import { UseInstallHubIntegrationReturn } from './useInstallHubIntegration.decl';

import {
  HubIntegrationInstallInformationQuery,
  HubIntegrationInstallInformationQueryVariables,
} from '@generated/HubIntegrationInstallInformationQuery';
import { HUB_INTEGRATION_INSTALL_INFORMATION_QUERY } from '@graphql/queries/HubIntegrationInstallInformationQuery';
import { useGlobalData } from '@hooks/useGlobalData/useGlobalData';
import { useGraphQuery } from '@hooks/useQuery/useGraphQuery';
import { useParams } from 'react-router-dom';

/**
 * Custom hook to get hub integration data.
 * @returns an object with the integration data, its loading and error state.
 */
export const useInstallHubIntegration = (): UseInstallHubIntegrationReturn => {
  const { applicationId } = useParams();
  const { currentCompany } = useGlobalData();

  const { data, loading, error } = useGraphQuery<
    HubIntegrationInstallInformationQuery,
    HubIntegrationInstallInformationQueryVariables
  >(HUB_INTEGRATION_INSTALL_INFORMATION_QUERY, {
    variables: {
      companyId: currentCompany.id,
      integrationId: parseInt(applicationId),
    },
  });

  return { integration: data?.getHubIntegrationInstallInformation, loading, error };
};
