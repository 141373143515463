import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import type { UseHasScrollableContentReturn } from './useHasScrollableContent.decl';

/**
 * This hook allows us to determine if an element has bottom scrollable content or not.
 *
 * @example
 * ```tsx
 * const { onScrollHandler, scrollableContainerRef, hasScrollableContentBottom } =
    useHasScrollableContent<HTMLDivElement>();

   <Box ref={scrollableContainerRef} onScroll={onScrollHandler} style={{ overflowY: 'auto', boxShadow: hasScrollableContentBottom ? 'box-shadow: 10 10 10 black' : '' }}>
    {children}
   </Box>
 * ```
 */
export function useHasScrollableContent<
  T extends HTMLUnknownElement
>(): UseHasScrollableContentReturn<T> {
  const scrollableContainerRef = useRef<T>(null);
  const [hasScrollableContentBottom, setHasScrollableContentBottom] = useState(false);

  const updateScrollableContentBottom = useCallback((element: T) => {
    const { clientHeight, scrollHeight, scrollTop } = element;

    const isAtTheTop = scrollTop === 0;
    const isInBetween = scrollTop > 0 && clientHeight < scrollHeight - scrollTop;
    const isAtTheBottom = clientHeight === scrollHeight - scrollTop;

    setHasScrollableContentBottom((isAtTheTop || isInBetween) && !isAtTheBottom);
  }, []);

  const onScrollHandler = useCallback(
    (event: React.UIEvent<T>) => {
      updateScrollableContentBottom(event.currentTarget);
    },
    [updateScrollableContentBottom]
  );

  useEffect(() => {
    /**
     * jest deliberately does not support layout properties nor APIs that
     * rely on them, such as ResizeObserver.
     *
     * Therefore, there's no need to cover this line.
     */
    /* istanbul ignore next */
    const resizeObserver = new ResizeObserver((entries) => {
      requestAnimationFrame(() => {
        entries.forEach((entry) => {
          updateScrollableContentBottom(entry.target as T);
        });
      });
    });

    if (scrollableContainerRef.current) {
      resizeObserver.observe(scrollableContainerRef.current);
    }

    return () => resizeObserver.disconnect();
  }, [updateScrollableContentBottom]);

  const useHasScrollableContentReturn = useMemo(
    () => ({
      hasScrollableContentBottom,
      scrollableContainerRef,
      onScrollHandler,
    }),
    [hasScrollableContentBottom, onScrollHandler]
  );

  return useHasScrollableContentReturn;
}
