/* eslint-disable @typescript-eslint/naming-convention */
import { useCallback } from 'react';

import { useApolloClient } from '@apollo/client';
import { NumberDetailQuery_numberDetail } from '@generated/NumberDetailQuery';
import {
  UpdateNumberMutation,
  UpdateNumberMutationVariables,
} from '@generated/UpdateNumberMutation';
import { UPDATE_NUMBER_MUTATION } from '@graphql/mutations/UpdateNumberMutation';
import { LINE_BUSINESS_HOURS_QUERY } from '@graphql/queries/LineBusinessHoursQuery';
import { GenericFormError } from '@helpers/errors.helpers';
import { useGraphMutationWithFeedback } from '@hooks/useMutation';
import { useNumberDetail } from '@hooks/useNumberDetail';
import { useTracker } from '@hooks/useTracker/useTracker';

interface UseUpdateNumberReturnType {
  updateNumberForm: (
    number: {
      name?: string;
      ivr?: boolean;
      time_zone?: string;
    },
    options?: { successMessage?: string }
  ) => Promise<Record<string, string> | GenericFormError | null>;
  number: NumberDetailQuery_numberDetail | null | undefined;
}

export function useUpdateNumberForm(): UseUpdateNumberReturnType {
  const { number } = useNumberDetail();
  const { track } = useTracker();

  const [updateNumberMutation] = useGraphMutationWithFeedback<
    UpdateNumberMutation,
    UpdateNumberMutationVariables
  >(UPDATE_NUMBER_MUTATION, { type: 'snackbar' });
  const client = useApolloClient();

  const updateNumberForm = useCallback(
    async (values, { successMessage } = {}) => {
      await updateNumberMutation(
        {
          variables: {
            input: {
              data: {
                id: parseInt(number!.id),
                type: 'number',
                attributes: {
                  ...values,
                },
              },
            },
            numberId: parseInt(number!.id),
          },
          update: async (cache) => {
            const { data } = await client.query({
              query: LINE_BUSINESS_HOURS_QUERY,
              variables: { lineID: number!.id },
            });

            cache.writeQuery({
              query: LINE_BUSINESS_HOURS_QUERY,
              data: {
                __typename: 'BusinessHours',
                getLineBusinessHours: {
                  ...data?.getLineBusinessHours,
                  timezone: values.time_zone,
                },
              },
              variables: {
                lineID: number!.id,
              },
            });
          },
          optimisticResponse: {
            updateNumber: {
              ...number,
              ...values,
            },
          },
        },
        { successMessage }
      );

      track({
        event: 'number_settings_edited',
        payload: {
          number_id: number?.id,
          settings_section: 'Settings',
          setting: 'General - Number name',
          action: 'edited',
        },
      });

      return null;
    },
    [number, track, updateNumberMutation]
  );

  return { updateNumberForm, number };
}
