const aircallConfig = {
  name: 'Aircall',
  appName: 'Aircall',
  faviconUrl: 'https://cdn.aircall.io/icos/aircall_favicon.ico',
  logoUrl: 'https://cdn.aircall.io/icos/logo-aircall.ico',
  downloadAppUrl: 'https://aircall.io/download',
  downloadLinkIos: 'https://apps.apple.com/us/app/aircall/id1021338425',
  downloadLinkAndroid: 'https://play.google.com/store/apps/details?id=com.aircall',
  domainList: [
    'dashboard.aircall-staging.com',
    'dashboard.aircall-qa.com',
    'dashboard.aircall.io',
    'aircall-local.aircall-develop.com',
  ],

  cookieDomain: {
    development: 'localhost',
    staging: 'dashboard.aircall-staging.com',
    production: 'dashboard.aircall.io',
    qa: 'dashboard.aircall-qa.com',
  },
  backendIssuedCookieDomain: {
    development: 'localhost',
    staging: '.aircall-staging.com',
    production: '.aircall.io',
    qa: '.aircall-qa.com',
  },
  legacyDashboardUrl: {
    development: 'http://localhost:4000/#/',
    staging: 'https://embed.dashboard.aircall-staging.com/',
    qa: 'https://embed.dashboard.aircall-qa.com/',
    production: 'https://embed.dashboard.aircall.io/',
  },
  featureSet: {
    shouldShowSignupLink: true,
    shouldShowReferAFriendButton: true,
    shouldShowGoogleSignIn: true,
    shouldShowHelpLinks: true,
    shouldShowCompanyAccountPage: true,
    shouldShowFeedbackButton: true,
    shouldShowFirefoxExtension: true,
    // Re-enable this once the extension is listed again
    shouldShowEdgeExtension: false,
    shouldShowOnboardingPortal: true,
    shouldShowOnboardingWebinars: true,
    shouldShowLoginBackgroundImage: false,
    shouldShowSidebarBottomLogo: false,
    enableSaml: true,
    useEmailMfa: true,
  },
  assetsIdentifier: 'aircall',
};

export default aircallConfig;
