import { gql } from '@apollo/client';

export const HUB_INTEGRATION_INSTALL_INFORMATION_QUERY = gql`
  query HubIntegrationInstallInformationQuery($companyId: Int!, $integrationId: Int!) {
    getHubIntegrationInstallInformation(companyId: $companyId, integrationId: $integrationId)
      @rest(
        type: "HubIntegrationInstallInformation"
        path: "hub/companies/{args.companyId}/applications/{args.integrationId}"
        endpoint: "v3"
      ) {
      id
      name
      logo
      comingSoon
      deleted
      description {
        en
        fr
      }
      subtitle
      source
      traySolutionId
      installUri
      learnMoreUri
    }
  }
`;
