import { gql } from '@apollo/client';

// common fields of UPDATE_NUMBER_MUTATION and NUMBER_DETAIL_QUERY
export const NUMBER_DETAIL_FRAGMENT = gql`
  fragment NumberDetailFragment on Number {
    id
    name
    number
    numberFormatted
    activationState
    ivr
    iso3166Alpha2
    state
    verificationState
    respectQueueingTime
    priority
    mandatoryTaggingActivated
    queueCallbackActivated
    allowInboundRecordingActions
    allowOutboundRecordingActions
    inboundRecordingActivated
    outboundRecordingActivated
    welcomeMessage
    welcomeMessageContent
    welcomeMessageEnabled
    welcomeMessageLocked
    ringingTone
    waitingMusic
    waitingMusicEnabled
    musicOnHold
    missedCallMessage
    missedCallVoicemail
    closedMessage
    closedMessageEnabled
    afterHoursMessage
    afterHoursVoicemail
    open
    ivrMessage
    smsEnabled
    replayIvrMessage
    flowEditorEnabled
    numberUuid
    timeZone
  }
`;
