import { useCallback } from 'react';

import styled from 'styled-components';

import {
  Box,
  Button,
  Flex,
  Spacer,
  Typography,
  TypographyVariants,
  useTheme,
} from '@aircall/tractor-v2';
import awDesktopImage from '@assets/images/aircall-workspace.png';
import { ReactComponent as AppleBadge } from '@assets/images/apple_badge.svg';
import { ReactComponent as GooglePlayBadge } from '@assets/images/google_play_badge.svg';
import { DynamicAssetComponent } from '@components/DynamicAssetComponent';
import { LeanHeader } from '@components/LeanHeader';
import { ExternalLink } from '@components/Link';
import { ResellerConfig } from '@config/resellers.config';
import { DOWNLOAD_APP_ROUTE_PHONE } from '@constants/urls.constants';
import { Loading } from '@dashboard/library';
import { CurrentCompanyQuery } from '@generated/CurrentCompanyQuery';
import { CURRENT_COMPANY_QUERY } from '@graphql/queries/CurrentCompanyQuery';
import { useAuthenticationState } from '@hooks/useAuthenticationState';
import { useBreakpointSwitch } from '@hooks/useBreakpointSwitch/useBreakpointSwitch';
import { useFeaturesFlags } from '@hooks/useFeatures/useFeaturesFlags';
import { useGraphQuery } from '@hooks/useQuery';
import { useTracker } from '@hooks/useTracker/useTracker';
import { useTranslation } from 'react-i18next';

const StyledLink = styled.a`
  width: max-content;
`;

const StyledFlex = styled(Flex)`
  svg {
    width: 250px;
  }
`;

export function DownloadAppScreen() {
  const { data } = useGraphQuery<CurrentCompanyQuery>(CURRENT_COMPANY_QUERY, { throwError: true });
  const { space } = useTheme();
  const titleVariantResponsive = useBreakpointSwitch<TypographyVariants>([
    'headingBoldL',
    'headingBoldL',
    'headingBoldL',
    'headingBoldXL',
    'headingBoldXL',
  ]);
  const { t } = useTranslation();
  const { track } = useTracker();
  const {
    actions: { signOut },
  } = useAuthenticationState();
  const { appName, logoUrl, name, downloadAppUrl, downloadLinkIos, downloadLinkAndroid } =
    ResellerConfig;

  const { enableAircallWorkspace } = useFeaturesFlags();
  const downloadLinkWebApp = enableAircallWorkspace ? downloadAppUrl : DOWNLOAD_APP_ROUTE_PHONE;

  const handleSignOut = useCallback(() => {
    signOut();
  }, [signOut]);

  const handleTracking = useCallback(() => {
    track({
      event: 'external_link_opened',
      payload: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        link_destination_domain: downloadLinkWebApp,
      },
    });
  }, [downloadLinkWebApp, track]);

  if (!data?.currentCompany) {
    return <Loading data-test='download-app-screen-loading' />;
  }

  const { name: companyName } = data.currentCompany;

  return (
    <Flex overflow='auto' h='100%' justifyContent='center' alignItems='center'>
      <LeanHeader data-test='download' logoUrl={logoUrl} altText={name} companyName={companyName} />
      <Flex
        alignItems={{
          _: 'center',
          lg: 'flex-start',
        }}
        justifyContent='center'
        mt={{
          _: space.xxxl * 2,
          lg: 0,
        }}
        mb={{
          _: 'l',
          lg: 0,
        }}
        flexDirection={{
          _: 'column',
          lg: 'row',
        }}
      >
        <Flex
          w='50%'
          justifyContent={{
            _: 'center',
            lg: 'flex-end',
          }}
          padding={space.xs}
          display={{
            _: 'none',
            lg: 'flex',
          }}
        >
          {enableAircallWorkspace ? (
            <img
              src={awDesktopImage}
              height={656}
              alt='aw-desktop-app'
              data-test='download-desktop-aw-app-image'
            />
          ) : (
            <DynamicAssetComponent
              name={ResellerConfig.getAssetPath('desktop-app')}
              height={656}
              data-test='download-desktop-app-image'
            />
          )}
        </Flex>
        <Spacer
          direction='vertical'
          space={{
            _: 's',
            lg: 'xl',
          }}
          alignItems={{
            _: 'center',
            lg: 'flex-start',
          }}
          w={{
            _: '85%',
            lg: '50%',
          }}
        >
          <Spacer
            direction='vertical'
            space='xxs'
            mt='l'
            w={{
              _: '100%',
              lg: '80%',
            }}
            alignItems={{
              _: 'center',
              lg: 'flex-start',
            }}
          >
            <Typography
              variant={titleVariantResponsive}
              fontWeight={700}
              textAlign={{
                _: 'center',
                lg: 'left',
              }}
            >
              {t('download_app_screen.title', { appName })}
            </Typography>
            <StyledFlex
              w='100%'
              justifyContent='center'
              alignItems='center'
              padding='xs'
              display={{
                _: 'flex',
                lg: 'none',
              }}
            >
              {enableAircallWorkspace ? (
                <img
                  src={awDesktopImage}
                  height={328}
                  alt='aw-desktop-app'
                  data-test='download-desktop-aw-app-image'
                />
              ) : (
                <DynamicAssetComponent
                  name={ResellerConfig.getAssetPath('desktop-app')}
                  height={328}
                  data-test='download-desktop-app-image'
                />
              )}
            </StyledFlex>
            <Typography
              variant='headingLightS'
              fontWeight={400}
              textAlign={{
                _: 'center',
                lg: 'left',
              }}
            >
              {enableAircallWorkspace
                ? t('download_app_screen.subtitle_aw', { appName })
                : t('download_app_screen.subtitle', { appName })}
            </Typography>
            <Flex
              flexDirection={{
                _: 'column',
                lg: 'row',
              }}
              alignItems='center'
              mt='xs'
            >
              <Button
                as={StyledLink}
                href={downloadLinkWebApp}
                target='_blank'
                data-test='download-app-button'
                onClick={handleTracking}
              >
                {t('download_app_screen.download_app', { appName })}
              </Button>

              <Box
                mt={{
                  _: 'xxs',
                  lg: '0',
                }}
                ml={{
                  _: '0',
                  lg: 'm',
                }}
              >
                <Button mode='link' onClick={handleSignOut} data-test='download-app-login-link'>
                  {t('download_app_screen.login_text')}
                </Button>
              </Box>
            </Flex>
          </Spacer>
          <Spacer direction='vertical' space='m'>
            <Typography
              variant='bodySemiboldM'
              textAlign={{
                _: 'center',
                lg: 'left',
              }}
            >
              {t('download_app_screen.use_mobile_apps')}
            </Typography>
            <Spacer space='s'>
              <ExternalLink
                href={downloadLinkAndroid}
                target='_blank'
                data-test='download-google-play-badge'
              >
                <GooglePlayBadge style={{ maxHeight: '48px' }} />
              </ExternalLink>
              <ExternalLink href={downloadLinkIos} target='_blank' data-test='download-apple-badge'>
                <AppleBadge style={{ maxHeight: '48px' }} />
              </ExternalLink>
            </Spacer>
          </Spacer>
        </Spacer>
      </Flex>
    </Flex>
  );
}
