import { DownloadAppButton } from './DownloadAppButton';
import { DownloadOtherAppLinks } from './DownloadOtherAppLinks';
import { OnboardingDownloadLink } from './OnboardingDownloadLink';

import {
  AndroidFilled,
  AppleFilled,
  ChromeFilled,
  EdgeFilled,
  FirefoxFilled,
} from '@aircall/icons';
import { Box, Flex, Spacer, Typography } from '@aircall/tractor-v2';
import { getResellerConfig } from '@config/resellers.config';
import { getNextOS, getOperatingSystem } from '@dashboard/library';
import { useFeaturesFlags } from '@hooks/useFeatures/useFeaturesFlags';
import { useTranslation } from 'react-i18next';

export function OnboardingDownloadSection() {
  const { t } = useTranslation();
  const { currentOS } = getOperatingSystem();
  const nextOS = getNextOS(currentOS);
  const {
    featureSet: { shouldShowEdgeExtension, shouldShowFirefoxExtension },
  } = getResellerConfig();
  const {
    dashboardOnboardingFirefoxExtensionDisplayed,
    enableAircallWorkspace,
    enableAircallWorkspaceMigration,
  } = useFeaturesFlags();
  const showFirefoxExtension =
    dashboardOnboardingFirefoxExtensionDisplayed && shouldShowFirefoxExtension;

  // Render multiple buttons to download Mac if AW is rolled out
  // "mac" will be the version for Mac silicone by default
  const aircallWorkspaceFlex = (
    <>
      <Typography variant='headingBoldS'>{t(`onboarding.application_aw.title`)}</Typography>
      <Flex flexDirection='column' alignItems='left'>
        {currentOS === 'mac' ? (
          <>
            <DownloadAppButton os={currentOS} appType='application_aw' />
            <DownloadAppButton os='macIntel' appType='application_aw' />
            <DownloadAppButton os='macPkg' appType='application_aw' />
          </>
        ) : (
          <>
            <DownloadAppButton os={currentOS} appType='application_aw' />
            <DownloadAppButton os='windowsExe' appType='application_aw' />
          </>
        )}
        <DownloadOtherAppLinks os={nextOS} appType='application_aw' />
      </Flex>
    </>
  );

  const phoneFlex = (
    <>
      <Typography variant='headingBoldS'>{t(`onboarding.application.title`)}</Typography>
      <Flex flexDirection='column' alignItems='left'>
        <DownloadAppButton os={currentOS} appType='application' />
        <DownloadOtherAppLinks os={nextOS} appType='application' />
      </Flex>
    </>
  );

  return (
    <Spacer direction='vertical' space='s' fluid>
      {/* AW is rolled out or AW migration is enabled render AW links */}
      {enableAircallWorkspace || enableAircallWorkspaceMigration ? aircallWorkspaceFlex : phoneFlex}
      {/* For customers in AW migration  we should render both AW and Phone links */}
      {enableAircallWorkspaceMigration ? phoneFlex : null}
      <Box
        borderColor='neutral-500'
        backgroundColor='white'
        borderStyle='solid'
        borderWidth={1}
        borderRadius={8}
        overflow='hidden'
        padding='m'
      >
        <Flex flexDirection='row'>
          <Box flex={1}>
            <Spacer direction='vertical' space='s'>
              <Typography variant='bodySemiboldM'>
                {t('onboarding.application.mobile_app_title')}
              </Typography>
              <OnboardingDownloadLink applicationName='Android' iconComponent={AndroidFilled} />
              <OnboardingDownloadLink applicationName='iOS' iconComponent={AppleFilled} />
            </Spacer>
          </Box>
          <Box flex={1}>
            <Spacer direction='vertical' space='s'>
              <Typography variant='bodySemiboldM'>
                {t('onboarding.application.browser_extension_title')}
              </Typography>
              <OnboardingDownloadLink applicationName='Chrome' iconComponent={ChromeFilled} />
              {shouldShowEdgeExtension && (
                <OnboardingDownloadLink applicationName='Edge' iconComponent={EdgeFilled} />
              )}
              {showFirefoxExtension && (
                <OnboardingDownloadLink applicationName='Firefox' iconComponent={FirefoxFilled} />
              )}
            </Spacer>
          </Box>
        </Flex>
      </Box>
    </Spacer>
  );
}
