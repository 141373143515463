import { useCallback } from 'react';

import { useInstallHubIntegration } from './hooks/useInstallHubIntegration';

import { ApplicationPageLayout } from '@components/ApplicationPageLayout/ApplicationPageLayout';
import { APPLICATIONS_SOURCES } from '@constants/integrations.constants';
import { INTEGRATION_FULL_ROUTES } from '@constants/routes.constants';
import { useNavigateWithParamsReplace } from '@hooks/useNavigateWithParamsReplace';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export function ApplicationHubPage() {
  const { integration, loading } = useInstallHubIntegration();
  const { source, name = '', logo } = integration || {};
  const { applicationName, applicationType = 'hub', applicationId } = useParams();
  const navigate = useNavigateWithParamsReplace();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const handleInstallButtonClick = useCallback(
    () =>
      navigate(INTEGRATION_FULL_ROUTES.FLOW_INSTALL, {
        applicationName,
        applicationType,
        applicationId,
      }),
    [applicationName, navigate, applicationType, applicationId]
  );
  const description =
    integration?.description.fr && integration?.description.fr !== '' && currentLanguage === 'fr-FR'
      ? (integration?.description.fr as string)
      : (integration?.description.en as string);
  return (
    <ApplicationPageLayout
      description={description}
      name={name}
      logo={logo}
      loading={loading}
      learnMoreUri={integration?.learnMoreUri}
      shouldShowInstallButton={source === APPLICATIONS_SOURCES.TRAY || !!integration?.installUri}
      onInstallButtonClick={handleInstallButtonClick}
    />
  );
}
