import React from 'react';

import { ErrorBoundaryProps, ErrorBoundaryState } from './ErrorBoundary.decl';

import { GenericErrorScreen } from '@components/GenericErrorScreen/GenericErrorScreen';
import { NotFoundErrorScreen } from '@components/NotFoundErrorScreen/NotFoundErrorScreen';
import { logger } from '@config/logger.config';
import { datadogRum } from '@datadog/browser-rum';
import { NotFoundError } from '@helpers/errors.helpers';
import { useLocation } from 'react-router';

class BaseErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      error: null,
    };
  }

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidUpdate(nextProps: ErrorBoundaryProps) {
    const { location } = this.props;
    const { error } = this.state;

    if (location !== nextProps.location && error) {
      this.setState({ error: null });
    }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    logger.error(error.message, { error, errorInfo });
    datadogRum.addError(error, errorInfo);
  }

  render() {
    const { error } = this.state;
    const { children } = this.props;

    if (error instanceof NotFoundError) {
      return <NotFoundErrorScreen />;
    }

    if (error) {
      return <GenericErrorScreen />;
    }

    return children;
  }
}

export function ErrorBoundary({ children }: { children: React.ReactNode }) {
  const location = useLocation();

  return <BaseErrorBoundary location={location}>{children}</BaseErrorBoundary>;
}
