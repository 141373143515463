import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';

import {
  EMBED_MESSAGE_TYPE,
  EventHandlers,
  LegacyDashboardContextType,
  Message,
  NavigationStartedMessage,
  PUSHER,
  ReduxAction,
  ReduxActionAppLoadingProgressPayload,
} from './LegacyDashboard.decl';
import {
  LegacyNavigationPayload,
  findLegacyRouteFromNewRoute,
  findNewRouteFromLegacyRoute,
} from './navigation/embed-navigation';
import { handleReduxAction } from './redux/reduxActions';

import { useApolloClient, useSubscription } from '@apollo/client';
import { ResellerConfig } from '@config/resellers.config';
import { AIRCALL_API_URL, APP_CONFIG, PUSHER_API_KEY } from '@constants/environment.constants';
import {
  GetPusherConfigQuery,
  GetPusherConfigQuery_getNotificationsConfiguration,
} from '@generated/GetPusherConfigQuery';
import {
  UpdatePusherConfigSubscription,
  UpdatePusherConfigSubscription_onNotificationsConfigurationChange,
} from '@generated/UpdatePusherConfigSubscription';
import { GET_PUSHER_CONFIG } from '@graphql/queries/GetPusherConfig';
import { UPDATE_PUSHER_CONFIG_SUBSCRIPTION } from '@graphql/subscriptions/UpdatePusherConfigSubscription';
import { useAppLayout } from '@hooks/useAppLayout/useAppLayout';
import { useAuthenticationState } from '@hooks/useAuthenticationState';
import { useFeaturesFlags } from '@hooks/useFeatures/useFeaturesFlags';
import { useGlobalData } from '@hooks/useGlobalData/useGlobalData';
import { useGraphLazyQuery } from '@hooks/useLazyQuery';
import { useNavigateWithParamsReplace } from '@hooks/useNavigateWithParamsReplace';
import { usePrevious } from '@hooks/usePrevious';
import { authorizeChannelsFactory } from '@services/authorizeChannelsFactory';
import noop from 'lodash-es/noop';

import { GenericReduxAction } from '.';

const IFRAME_URL = `${ResellerConfig.legacyDashboardUrl}?is_embedded=true&version=${APP_CONFIG.release}`;

const EMBED_MESSAGE_TYPE_LIST = Object.values(EMBED_MESSAGE_TYPE).filter(
  (value) => typeof value === 'string'
);

const getPusherOptions = (
  isFFEnabled: boolean | undefined,
  fetchedOptions?:
    | UpdatePusherConfigSubscription_onNotificationsConfigurationChange
    | GetPusherConfigQuery_getNotificationsConfiguration
) => {
  const isDynamicConfigEnable = isFFEnabled && fetchedOptions;
  return {
    key: isDynamicConfigEnable ? fetchedOptions.appKey : PUSHER_API_KEY,
    cluster: isDynamicConfigEnable ? fetchedOptions.cluster : 'mt1',
    activityTimeout: 10000, // Send a ping after 10 secs of inactivity
    pongTimeout: 5000, // Wait the ping response 5secs before re-connect
    enabledTransports: ['ws', 'xhr_streaming'],
    disabledTransports: ['xhr_streaming'],
  };
};

export const LegacyDashboardContext = React.createContext<LegacyDashboardContextType>({
  progress: 0,
  isLoaded: false,
  mount: () => undefined,
  unmount: () => undefined,
  navigate: () => undefined,
  show: () => undefined,
  getPusherConfigError: undefined,
  updatePusherConfigError: undefined,
});

/**
 * Create an iframe connected to the legacy dashboard and
 * pass down the the utility functions to manipulate it to
 * child components
 */
export function LegacyDashboardProvider({ children }: { children: React.ReactNode }) {
  const iframe = useRef<HTMLIFrameElement | null>(null);
  const messageQueue = useRef<Message[]>([]);
  const mountedElement = useRef<{
    elem: HTMLElement;
    resizeObserver: ResizeObserver;
    events: EventHandlers;
  }>();
  const dequeueTimeout1 = useRef<number>(0);
  const dequeueTimeout2 = useRef<number>(0);

  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [progress, setProgress] = useState(0);

  const { dashboardEnabledNewSidebar, enablePusherConfig } = useFeaturesFlags();

  const [getPusherConfig, { error: getPusherConfigError }] =
    useGraphLazyQuery<GetPusherConfigQuery>(GET_PUSHER_CONFIG, {
      context: { exposeLoggingData: true },
    });
  const { error: updatePusherConfigError } = useSubscription<UpdatePusherConfigSubscription>(
    UPDATE_PUSHER_CONFIG_SUBSCRIPTION,
    {
      skip: !enablePusherConfig,
      onSubscriptionData: ({ subscriptionData }) => {
        const { data } = subscriptionData;
        if (iframe.current?.contentWindow && data?.onNotificationsConfigurationChange) {
          iframe.current.contentWindow.postMessage(
            {
              type: 'update-pusher-config',
              options: {
                authEndpoint: `${AIRCALL_API_URL}pusher/auth`,
                ...getPusherOptions(enablePusherConfig, data.onNotificationsConfigurationChange),
              },
            },
            IFRAME_URL
          );
        }
      },
    }
  );

  const {
    currentUser: { language },
  } = useGlobalData();
  const prevLanguage = usePrevious<string>(language);
  const [styles, setStyles] = useReducer(
    (currentStyles: CSSProperties, newStyles: CSSProperties) => ({
      ...currentStyles,
      ...newStyles,
    }),
    {
      border: 'none',
      borderRadius: dashboardEnabledNewSidebar ? 24 : 0,
      position: 'absolute',
      top: 0,
      left: 0,
      width: 0,
      height: 0,
      visibility: 'hidden',
    }
  );
  const navigate = useNavigateWithParamsReplace();
  const client = useApolloClient();
  const {
    actions: { signOut },
  } = useAuthenticationState();
  const { toggleFullScreen } = useAppLayout();

  const legacyEmbedNavigate = (nav: LegacyNavigationPayload) => {
    /* istanbul ignore else  */
    if (iframe.current?.contentWindow) {
      iframe.current.contentWindow.postMessage(
        {
          type: 'navigate',
          ...nav,
          // We make sure modals are closed when navigating
          // to another page
          actions: [
            {
              type: 'reset journey',
              payload: { journeyName: 'numberCreation' },
            },
            {
              type: 'reset journey',
              payload: { journeyName: 'numberProofOfId' },
            },
            ...(nav.actions ?? []),
          ],
        },
        IFRAME_URL
      );
    }
  };

  useEffect(() => {
    // Reset iframe state when user language is updated
    if (prevLanguage && language && language !== prevLanguage) {
      setProgress(0);
      setIsLoaded(false);
    }
  }, [language, prevLanguage]);

  const context = useMemo(
    () => ({
      /**
       * Indicates loading progress
       */
      progress,

      /**
       * Indicates whether or not the legacy dashboard is
       * loaded and ready for interactions
       */
      isLoaded,

      /**
       * Indicates whether or the query to fetch pusher's config has failed
       */
      getPusherConfigError: enablePusherConfig ? getPusherConfigError : undefined,

      /**
       * Indicates whether or the subscription to update pusher's config has failed
       */
      updatePusherConfigError: enablePusherConfig ? updatePusherConfigError : undefined,

      /**
       * Attach the iframe to the given element.
       * The iframe will just overlap the given element by taking the exact same position and size.
       * This avoids changing iframe position in the DOM tree which would cause it to reload entirely.
       * @param elem - Elem to attach the iframe to
       * @param events - Events to listen to
       */
      mount: (
        elem: HTMLElement,
        events: EventHandlers = {
          onTransitionSucceeded: noop,
        }
      ) => {
        if (mountedElement.current) {
          throw new Error(
            'The dashboard legacy cannot be mounted on several places at the same time'
          );
        }

        mountedElement.current = {
          elem,
          events,
          resizeObserver: new ResizeObserver((entries: ResizeObserverEntry[]) => {
            requestAnimationFrame(() => {
              entries.forEach((entry) => {
                const { width, height, top, left } = entry.target.getBoundingClientRect();
                setStyles({ width, height, top, left });
              });
            });
          }),
        };
        mountedElement.current.resizeObserver.observe(mountedElement.current.elem);

        return undefined;
      },

      show: () => {
        setStyles({
          visibility: 'visible',
        });
        return undefined;
      },

      /**
       * Will detach the iframe from the DOM element it was attached to
       * and hide.
       * It will also stop listening to all events.
       */
      unmount: () => {
        mountedElement.current?.resizeObserver.disconnect();
        mountedElement.current = undefined;
        setStyles({
          // Resetting width, height, top, left is not necessary here since we use visibility hidden
          // we do it so that styling is cleaner
          width: 0,
          height: 0,
          top: 0,
          left: 0,
          visibility: 'hidden',
        });
        return undefined;
      },

      /**
       * Based on the route mapping, it will find the corresponding route on the Dashboard
       * and
       * @param path - Path of a page of the new dashboard
       * @param params - Route parameters
       * @returns
       */
      navigate: (path: string, params: Record<string, string>) => {
        const legacyNav = findLegacyRouteFromNewRoute(path, params);

        if (legacyNav) {
          legacyEmbedNavigate(legacyNav);
        }

        return undefined;
      },
    }),
    [progress, isLoaded, enablePusherConfig, getPusherConfigError, updatePusherConfigError]
  );

  /**
   * Called when a redux action is triggered from the legacy dashboard
   * Can cause navigation or cache update on the new Dashboard
   */
  const handleAction = useCallback(
    (action: ReduxAction) => {
      if (action && typeof action === 'object') {
        const { type } = action;
        if (type === 'app loading progress') {
          setProgress(
            (action as GenericReduxAction<ReduxActionAppLoadingProgressPayload>).payload.progress
          );
        } else {
          handleReduxAction(type, action, {
            navigate,
            client,
            // Avoid stale state when using useLocation
            location: window.location,
          });
        }
      }
    },
    [client, navigate, setProgress]
  );

  /**
   * Handle navigation event coming from the legacy dashboard
   * If the navigation need to be handled by the new dashboard, we do so.
   * Otherwise, we let the legacy dashboard handle it.
   */
  const handleNavigationStarted = useCallback(
    async ({ name, url, params }: NavigationStartedMessage) => {
      const legacyNav = {
        name,
        url,
        params,
      };
      const newUrl = findNewRouteFromLegacyRoute(legacyNav);

      const { data: pusherConfig } = enablePusherConfig
        ? await getPusherConfig()
        : { data: undefined };

      if (iframe.current?.contentWindow) {
        iframe.current.contentWindow.postMessage(
          {
            type: 'init-pusher-config',
            options: {
              authEndpoint: `${AIRCALL_API_URL}pusher/auth`,
              ...getPusherOptions(enablePusherConfig, pusherConfig?.getNotificationsConfiguration),
            },
          },
          IFRAME_URL
        );
      }

      if (newUrl) {
        navigate(newUrl);
      } else {
        legacyEmbedNavigate(legacyNav);
      }
    },
    [enablePusherConfig, getPusherConfig, navigate]
  );

  /**
   * Notify the component consuming the context that
   * the legacy dashboard has successfully navigated to
   * a new page
   */
  const handleNavigationSuccess = useCallback(() => {
    mountedElement.current?.events.onTransitionSucceeded();
  }, []);

  const dequeueMessage = useCallback(() => {
    // message is never undefined
    const message = messageQueue.current.shift()!;

    /* istanbul ignore next */
    if (message) {
      switch (message.type) {
        case EMBED_MESSAGE_TYPE.NAVIGATION_STARTED:
          handleNavigationStarted(message);
          break;
        case EMBED_MESSAGE_TYPE.NAVIGATION_SUCCEEDED:
          handleNavigationSuccess();
          break;
        case EMBED_MESSAGE_TYPE.LOGOUT:
          signOut();
          break;
        case EMBED_MESSAGE_TYPE.UNLOADED:
          setIsLoaded(false);
          break;
        case EMBED_MESSAGE_TYPE.LOADED:
          setIsLoaded(true);
          break;
        case EMBED_MESSAGE_TYPE.REDUX_ACTION:
          handleAction(message.action);
          break;
        case EMBED_MESSAGE_TYPE.ENTER_FULL_SCREEN:
          toggleFullScreen(true);
          break;
        case EMBED_MESSAGE_TYPE.EXIT_FULL_SCREEN:
          toggleFullScreen(false);
          break;
        default:
          break;
      }
    }

    // We wait for next render to handle the next message
    if (messageQueue.current.length) {
      clearTimeout(dequeueTimeout2.current);
      dequeueTimeout2.current = window.setTimeout(dequeueMessage, 0);
    }
  }, [handleNavigationStarted, handleNavigationSuccess, signOut, handleAction, toggleFullScreen]);

  const enqueueMessage = useCallback(
    async (event: MessageEvent<Message>) => {
      if (event.data.type === PUSHER.AUTHORIZE_CHANNEL) {
        try {
          const { payload } = event.data;
          const authorizer = authorizeChannelsFactory(client);
          const response = await authorizer(payload.cluster, [
            { channelName: payload.channelName, socketId: payload.socketId },
          ]);
          iframe.current?.contentWindow?.postMessage(
            {
              type: PUSHER.AUTHORIZE_CHANNEL_FULLFILLED,
              payload: response.data?.authorizeChannels.authorizations[0],
            },
            IFRAME_URL
          );
        } catch (e) {
          iframe.current?.contentWindow?.postMessage(
            {
              type: PUSHER.AUTHORIZE_CHANNEL_FAILED,
            },
            IFRAME_URL
          );
        }
        // receive socketId channelName
        // make a graphql mutation call to authorize
        // send back the response
      } else if (EMBED_MESSAGE_TYPE_LIST.includes(event.data.type)) {
        clearTimeout(dequeueTimeout1.current);
        const message = event.data;
        messageQueue.current.push(message);
        // Debounce dequeue -> when no more message is coming, we start to dequeue
        dequeueTimeout1.current = window.setTimeout(dequeueMessage, 100);
      }
    },
    [dequeueMessage, client]
  );

  useEffect(() => {
    window.addEventListener('message', enqueueMessage);
    return () => {
      window.removeEventListener('message', enqueueMessage);
    };
  }, [enqueueMessage]);

  useEffect(
    () => () => {
      clearTimeout(dequeueTimeout1.current);
      clearTimeout(dequeueTimeout2.current);
    },
    []
  );

  return (
    <LegacyDashboardContext.Provider value={context}>
      {children}
      <iframe
        // Make sure the iframe re-renders when user language is updated
        key={language}
        data-test='legacy-dashboard-iframe'
        title='Legacy Dashboard'
        ref={iframe}
        src={IFRAME_URL}
        style={styles}
      />
    </LegacyDashboardContext.Provider>
  );
}
