import styled from 'styled-components';

import { ArrowSendOutlined } from '@aircall/icons';
import { Flex, Icon, Typography, getColor, getSpace } from '@aircall/tractor-v2';
import { ExternalLink } from '@components/Link';
import { ResellerConfig } from '@config/resellers.config';
import { useTranslation } from 'react-i18next';

export type DownloadAppButtonVaiant = 'full' | 'half';

type DownloadAppButtonV2Props = {
  applicationName: string;
};

const StyledLink = styled(ExternalLink)`
  &:hover,
  &:focus {
    border-color: ${getColor('text-base')};
    color: ${getColor('text-base')};
    text-decoration: none;
  }
  width: 100%;
  padding: ${getSpace('xs')};
  border: 1px solid ${getColor('graphic-default')};
  border-radius: 8px;
  color: ${getColor('text-base')};
`;

export function DownloadAppButtonV2({ applicationName }: Readonly<DownloadAppButtonV2Props>) {
  const { t } = useTranslation();

  return (
    <StyledLink
      href={t(
        `resellers.${ResellerConfig.identifier}.onboarding.application_aw.download_link_${applicationName}`.toLowerCase()
      )}
      target='_blank'
      rel='noreferrer noopener'
      data-test='download-app-button-link'
    >
      <Flex flexDirection='row' alignItems='center' w='100%'>
        <Typography data-test='discover-card-heading' variant='bodySemiboldM'>
          {t(`onboarding.application_aw.download_for_${applicationName.toLowerCase()}`)}
        </Typography>
        <Icon
          ml='auto'
          my='auto'
          data-test='arrow-icon'
          component={ArrowSendOutlined}
          size={24}
          color='icon-default'
        />
      </Flex>
    </StyledLink>
  );
}
