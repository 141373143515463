import {
  Banner,
  BannerHeading,
  BannerIcon,
  BannerSuffix,
  Flex,
  ToastButton,
} from '@aircall/tractor-v2';
import { LegacyDashboard } from '@components/LegacyDashboard';
import { useDashboardExtensionContext } from '@dashboard/extension';
import { checkIsLiveMonitoringPlusEnabled } from '@dashboard/library';
import { checkIsLiveMonitoringAvailable } from '@helpers/liveMonitoring.helpers';
import { useFeaturesFlags } from '@hooks/useFeatures/useFeaturesFlags';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export function ActivityFeed() {
  const { t } = useTranslation();
  const {
    dashboardExtensionEnableLiveMonitoringExtension,
    showLivefeedDeprecationMessage,
    showLookerInAnalytics,
  } = useFeaturesFlags();
  const { currentCompany } = useDashboardExtensionContext();
  const navigate = useNavigate();

  const featureFlags = {
    dashboardExtensionEnableLiveMonitoringExtension,
    showLookerInAnalytics,
  } as Record<string, boolean>;
  const isLiveMonitoringAvailable = checkIsLiveMonitoringAvailable(featureFlags, currentCompany);
  const isLiveMonitoringPlusEnabled = checkIsLiveMonitoringPlusEnabled(featureFlags);
  const isBannerVisible = isLiveMonitoringAvailable && showLivefeedDeprecationMessage;

  const handleClick = () => {
    navigate(
      isLiveMonitoringPlusEnabled ? '/live_monitoring_plus/calls' : '/live_monitoring/calls'
    );
  };

  return (
    <Flex flexDirection='column' h='100%'>
      <Flex flexDirection='column' flexGrow={1} overflow='hidden'>
        {isBannerVisible && (
          <Banner data-test='activity-feed-deprecation-banner' variant='warning'>
            <BannerIcon />
            <BannerHeading>{t('activity_feed.deprecation_message.text_updated')}</BannerHeading>
            <BannerSuffix>
              <ToastButton data-test='banner-button' onClick={handleClick}>
                {t('activity_feed.deprecation_message.cta')}
              </ToastButton>
            </BannerSuffix>
          </Banner>
        )}
        <LegacyDashboard componentName={t('embedded_dashboard.components.activity_feed')} />
      </Flex>
    </Flex>
  );
}
