import { OnboardingDownloadSectionV2 } from './OnboardingDownloadSectionV2';
import { ResourceLinkV2 } from './ResourceLinkV2';

import { Box, Flex } from '@aircall/tractor-v2';

export function ResourcesSectionV2() {
  return (
    <Flex flexDirection='column' w='100%' as='section' data-test='resource-section'>
      <Box
        w='100%'
        marginRight={{
          _: '0',
          lg: 'm',
          xl: '0',
        }}
      >
        <OnboardingDownloadSectionV2 />
      </Box>
      <Box w='100%' mt='m'>
        <ResourceLinkV2 />
      </Box>
    </Flex>
  );
}
